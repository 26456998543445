import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageProps } from 'gatsby'
import site from '../_data/site.json'
import { SVG, easing } from '@svgdotjs/svg.js'
import useElementSize from '../hooks/useElementSize'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useHeaderLines from '../hooks/useHeaderLines'

const WhatWeDo: React.FC<PageProps> = ({ location }) => {
  const { whatwedo } = site.pages
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--what-we-do'),
      )
      const preIntroRect = getAbsoluteOffset(
        document.querySelector('.pre-intro'),
      )
      const introRect = getAbsoluteOffset(document.querySelector('.intro'))
      const weRect = getAbsoluteOffset(document.querySelector('.we'))
      const quoteOneRect = getAbsoluteOffset(document.querySelector('#quote-1'))
      const quoteTwoRect = getAbsoluteOffset(document.querySelector('#quote-2'))

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const polyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          preIntroRect.bottom,

          introRect.right,
          preIntroRect.bottom,

          introRect.right,
          weRect.top,

          weRect.left,
          weRect.top,

          quoteOneRect.left,
          quoteOneRect.top,

          quoteOneRect.right,
          quoteOneRect.top,

          quoteOneRect.right,
          quoteOneRect.bottom,

          quoteTwoRect.left,
          quoteTwoRect.top,

          quoteTwoRect.left,
          quoteTwoRect.bottom,

          0,
          quoteTwoRect.bottom,
        ])
        .stroke({
          width: 0,
        })
      const polylineLength = polyline.node.getTotalLength()
      polyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': polylineLength,
          'stroke-dasharray': polylineLength,
        })
        .animate({ duration: 5000, delay: 0 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout>
      <SEO title="What we do" />
      <main ref={pageRef} className={`container page--what-we-do`}>
        <div className="pre-intro" style={{ height: 50 }}></div>
        <div className="intro">
          <img src={whatwedo.intro.image} className="intro__image" />
          <h1 className="intro__title">{whatwedo.intro.heading}</h1>
          <div
            className="intro__content"
            dangerouslySetInnerHTML={{ __html: whatwedo.intro.content }}
          />
        </div>
        <div className="we grid grid-col-4">
          {whatwedo.we.map(
            (
              item: { name: string; heading: string; content: string },
              index: number,
            ) => {
              return (
                <div className={`we-col we-col--${item.name}`} key={index}>
                  <h3 className="we-col__title">{item.heading}</h3>
                  <div
                    className="we-col__content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              )
            },
          )}
        </div>
        <div className="quotes">
          {whatwedo.quotes.map(
            (quote: { heading: string; content: string }, index: number) => {
              return (
                <div id={`quote-${index + 1}`} className="quote" key={index}>
                  <h2 className="quote__title h1">{quote.heading}</h2>
                  <div
                    className="quote__content"
                    dangerouslySetInnerHTML={{ __html: quote.content }}
                  />
                </div>
              )
            },
          )}
        </div>
      </main>
    </Layout>
  )
}

export default WhatWeDo
